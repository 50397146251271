import React from 'react';
import { ChangePassword } from '@components';
import { Confirmation } from 'src/components/templates/confirmation';

type TProps = {
    token: string;
};

const Confirmacion = (props: TProps) => {
    return <Confirmation token={props.token} />;
};

export default Confirmacion;
