import { ContainerTransaction, PageToLoading } from '@components';
import { Layout, SEO } from '@componentsShared';
import React from 'react';

type TProps = {
    token: string;
};

export const Confirmation = (props: TProps) => {
    return (
        <Layout>
            <SEO
                slug="/carrito/confirmacion"
                title="Gana - Confirmacion"
                index='noindex, nofollow'
            />
                {/* <PageToLoading titulo='Se está procesando tu pago' primaryText='La suerte está echada, espera unos segundos, que giren las balotas y ' strongText='¡a ganar!' /> */}
                <ContainerTransaction idPayment={props.token}></ContainerTransaction>
        </Layout>
    );
};
